import Cookie from 'js-cookie';
import { setUsetifulTags } from 'usetiful-sdk';

import { ISubscription, IUserSubscription } from "../../interfaces/models/subscription"
import { IUser, TContext } from "../../interfaces/models/user"
import { IWorkspace } from "../../interfaces/models/workspace"
import { ActionType, IAuthData, TAuthState } from "../../interfaces/store/authStore"
import { useAuthStore } from "./useAuthStore"
import { useNavigate } from 'react-router-dom';
import { useExtensionRequest } from '../../services/apis/extension/useExtensionRequest';
import { IUserWorkspace } from '../../interfaces/response/workspace';

export const useAuthActions = () => {
    const { dispatch } = useAuthStore()
    const { putSessionTokens } = useExtensionRequest()
    const navigate = useNavigate();

    const setUser = (payload?: IUser) => {
        setUsetifulTags({
            userId: payload?._id,
            firstName: payload?.linkedinData?.firstName,
            lastName: payload?.linkedinData?.lastName,
          });
        return dispatch({
            type: ActionType.SET_USER,
            payload
        })
    }

    const setWorkspace = (payload?: IWorkspace) => {
        return dispatch({
            type: ActionType.SET_WORKSPACE,
            payload
        })
    }

    const setAuthData = (payload: IAuthData) => {
        return dispatch({
            type: ActionType.SET_AUTH_DATA,
            payload
        })
    }

    const setContext = (payload: TContext) => {
        return dispatch({
            type: ActionType.SET_CONTEXT,
            payload
        })
    }

    const setLifetime = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_LIFETIME,
            payload
        })
    }

    const setTrial = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_TRIAL,
            payload
        })
    }

    const setValidSubscription = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_VALID_SUBSCRIPTION,
            payload
        })
    }

    const setWorkspaceOwner = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_WORKSPACE_OWNER,
            payload
        })
    }

    const setWorkspaceAdmin = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_WORKSPACE_ADMIN,
            payload
        })
    }

    const setSubscription = (payload?: ISubscription | IUserSubscription) => {
        return dispatch({
            type: ActionType.SET_SUBSCRIPTION,
            payload
        })
    }

    const setDisablePremiumFeatures = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_DISABLE_PREMIUM_FEATURES,
            payload
        })
    }

    const setUserWorkspaces = (payload?: IUserWorkspace[]) => {
        return dispatch({
            type: ActionType.SET_USER_WORKSPACES,
            payload
        })
    }

    const resetAuthStore = () => {
        localStorage.removeItem('at')
        localStorage.removeItem('rt')

        Cookie.remove('at');
        Cookie.remove('rt');

        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    //Syncs
    const syncAccesToken = (accessToken: string): string | undefined => {
        localStorage.setItem('at', accessToken || '');
        Cookie.set('at', accessToken || '');

        return accessToken
    }

    const syncRefreshToken = (refreshToken: string): string | undefined => {
        localStorage.setItem('rt', refreshToken || '');
        Cookie.set('rt', refreshToken || '');

        return refreshToken
    }

    const logout = async () => {
        resetAuthStore();

        // add to the extension 
        await putSessionTokens({
            accessToken: '',
            refreshToken: ''})
    };

    const setExtensionInboxTaskId = (payload?: string) => {
        return dispatch({
            type: ActionType.SET_EXTENSION_INBOX_TASK_ID,
            payload
        })
    }

    const setDisconnectedConnectionsCount = (payload: any) => {
        return dispatch({
            type: ActionType.SET_DISCONNECTED_CONNECTIONS_COUNT,
            payload
        })
    }

    const setIsLoggedToLinkedin = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_IS_LOGGED_TO_LINKEDIN,
            payload
        })
    }

    const setScheduledRemoval= (payload: any) => {
        return dispatch({
            type: ActionType.SET_SCHEDULED_REMOVAL,
            payload
        })
    }

    return {
        setUser,
        setWorkspace,
        setAuthData,
        setContext,
        setLifetime,
        setTrial,
        setValidSubscription,
        setWorkspaceOwner,
        setWorkspaceAdmin,
        setSubscription,
        resetAuthStore,
        syncAccesToken,
        syncRefreshToken,
        setUserWorkspaces,
        setDisablePremiumFeatures,
        setExtensionInboxTaskId,
        logout,
        setDisconnectedConnectionsCount,
        setIsLoggedToLinkedin,
        setScheduledRemoval
    }
}


